<template>
  <div class="trait-kits">
    <h4>Kits</h4>

    <div class="trait-kits-body">
      <div v-for="(kitItem, index) in orderKits" :key="index">
        <div class="trait-kit-item">
          <h5>{{kitItem.personName}}</h5>
          <span class="status ml-1" v-show="kitItem.fileName !== ''"> 
            <checked-icon /> Uploaded
          </span>
        </div>
        <hr v-if="index !== orderKits.length - 1"/>
      </div>
    </div>
  </div>
</template>

<script>
import CheckedIcon from '@/layouts/icons/CheckedIcon.vue';

export default {
  components: {
    CheckedIcon,
  },
  props: {
  },
  data() {
    return {
      isShowDropdown: false,
      ethnicityList: [],
    }
  },
  methods: {    
    documentClick(event) {
      /* eslint-disable */
      try {
        if (!event.target.closest('.lab-dropdown-btn')) {
          this.$store.commit('traitCheckout/closeDropdowns');
        }
      } catch (error) {}
    },
    toggleDropdownMenu(kitItem) {
      kitItem.isShowDropdown = !kitItem.isShowDropdown;
    },

  },
  computed: {
    orderKits() {
      return this.$store.getters['traitCheckout/getCreateOrderData'].kits;
    },
  },
  mounted() {
  },
  beforeMount() {
    document.addEventListener("click", this.documentClick);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.documentClick);
  },
}
</script>

<style lang="scss" scoped>
.trait-kits {
  padding: 1rem 1.5rem;
  
  .trait-kits-body {
    padding: 1rem;

    hr {
      border-color: var(--lab-carbon-fiber) !important;
    }
  }
  h4 {
    font-size: 1.5rem;
    font-weight: bold;
  }

  .status {
    margin-right: 10px;
    font-size: 0.8rem;
    color: var(--lab-minted-elegance-color);
  }

  .trait-kit-item {
    display: flex;
    align-items: center;
    justify-content: space-between;

    h5 {
      font-size: 0.9rem;
    }
  }
}

</style>
