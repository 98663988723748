<template>
  <div class="checbox-confirm">
    <div class="d-flex align-items-center " v-if="isUserLoggedIn === false">
      <label class="lab-switch">
        <input type="checkbox" v-model="$store.state.account.userData.twoFactorAuthActive">
        <span class="slider round"></span>
      </label>
      <span class="lab-switch-desc">2 factor authentication (Recommended)</span> 
    </div>
    <div class="d-flex align-items-center mt-1">
        <label class="lab-switch">
          <input type="checkbox" v-model="$store.state.traitCheckout.createOrderData.isConfirmPrivacyPalicy" @change="validateCheckbox">
          <span class="slider round"></span>
        </label>
        <span class="lab-switch-desc">I read and agree to the <a class="privacy-policy" href="https://illustrativedna.com/privacy-policy/" target="_blank">privacy policy</a> (Obligatory)</span> 
    </div>
    <small class="text-danger" v-show="isValidPrivacyPolicy === false">You must accept privacy policy</small>
  </div>
</template>

<script>
export default {
  components: {
  },
  props: {
  },
  data() {
    return {
      isShowDropdown: false,
      isValidPrivacyPolicy: null,
    }
  },
  methods: {
    validateCheckbox() {
      this.isValidPrivacyPolicy = this.$store.state.traitCheckout.createOrderData.isConfirmPrivacyPalicy;
    },
  },
  computed: {
    productData() {
      return this.$store.getters['traitCheckout/getProductData'];
    },
    productQuantity() {
      return this.$store.getters['traitCheckout/getCreateOrderData'].productQuantity;
    },    
    isUserLoggedIn() {
      return this.$store.getters['account/getIsLoggedIn'];
    },
  },
}
</script>

<style lang="scss" scoped>
.checbox-confirm {
  padding: 1.2rem;
  .privacy-policy {
    color: var(--lab-minted-elegance-color);
  }
}

</style>
