<template>
  <div>
    <div class="checkout-card hidden-mobile" v-if="productData != null">
      <div class="upload-raw-container">
        <div class="upload-raw-body">
          <div class="title">
            <h4>Upload your file(s)</h4>
          </div>

          <div class="desc">
            <p>Upload your Raw DNA Data file from DNA Company</p>
          </div>

          <div class="select-quantity">
            <h6>How many files do you want to upload?</h6>
            <quantity-dropdown />
            <p>
              Upload your Raw DNA files for each field below and then give them a recognazible name i.e. ‘Mother’s DNA’
            </p>
          </div>

          <div class="kits mb-4">
            <div class="kit-item mb-2" 
              v-for="(kitItem, index) in orderKits"
              :key="index">

              <h6>{{index + 1}}.Kit</h6>
              <div class="kit-item-body">
                <div class="d-flex justify-content-start align-items-center">
                  <input type="text" class="form-control kit-name mr-1" v-model="kitItem.personName"
                    placeholder="Kit name"
                    :disabled="kitItem.preTempOrderRawDataGuid === null"
                    :class="{ 
                      'dashed-input': kitItem.emptyPersonName,
                      'filled-input': kitItem.personName.length >= 1,
                    }"/>

                  <button class="lab-btn past-coordinates" @click="openUpload(kitItem)"> <upload-icon /> Upload File </button>
                  
                  <span class="status ml-1" v-show="kitItem.fileName === ''"> 
                    <!-- <progress-icon v-if="!kitItem.isLoading"/> -->
                    <b-spinner  v-if="kitItem.isLoading" class="mr-1" small />
                    <!-- 0 % -->
                  </span>
                  <span class="status ml-1" v-show="kitItem.fileName !== ''"> 
                    <checked-icon /> Completed
                  </span>
                </div>
                <div class="d-flex justify-content-start align-items-center">
                  <span class="delete-kit" @click="removeUploadFile(kitItem)"  v-show="kitItem.fileName !== ''">
                    Delete
                  </span>
                </div>
              </div>
              <small class="text-danger" v-show="kitItem.emptyPreTempOrderRawDataGuid">
                You must upload raw data file
              </small>
            </div>
          </div>

          <div class="warn-cost">
            <p>Having a problem uploading your File? <a href="https://illustrativedna.com/contact/" target="_blank">Contact support</a> </p>
            <hr />
            <div class="cost">Cost: <span>{{ productData != null? productData.product.currencyUnit : ''}}{{subtotal}} </span></div>
          </div>
        </div>
      </div>
      <div class="step-buttons">
        <button class="lab-btn back" @click="back()">Back</button>
        <button class="lab-btn next valid" @click="submit()">
          Next
        </button>
      </div>
    </div>

    <div class="checkout-card-mobile hidden-desktop" v-if="productData != null">
      <div class="upload-raw-container">
        <div class="title">
          <h4>Upload your file(s)</h4>
        </div>
        <div class="upload-raw-body">
          <div class="select-quantity">
            <h6>How many files do you want to upload?</h6>
            <div class="d-flex justify-content-center mt-1 mb-1">
              <quantity-dropdown />
            </div>
            <p>
              Upload your Raw DNA files for each field below and then give them a recognazible name i.e. ‘Mother’s DNA’
            </p>
          </div>

          <div class="kits mb-4 mobile-body">
            <div class="kit-item mb-2" 
              v-for="(kitItem, index) in orderKits"
              :key="index">

              <h6>{{index + 1}}.Kit</h6>
              <div class="kit-item-body">
                <div class="d-flex justify-content-between align-items-center">
                  <input type="text" class="form-control kit-name mr-1" v-model="kitItem.personName"
                    placeholder="Kit name"
                    :disabled="kitItem.preTempOrderRawDataGuid === null"
                    :class="{ 
                      'dashed-input': kitItem.emptyPersonName,
                      'filled-input': kitItem.personName.length >= 1,
                    }"/>
                </div>
                <div class="d-flex justify-content-between align-items-center">
                  <button class="lab-btn past-coordinates" v-show="kitItem.fileName === ''" @click="openUpload(kitItem)"> <upload-icon /></button>
                  
                  <span class="status ml-1" v-show="kitItem.fileName === ''"> 
                    <!-- <progress-icon v-if="!kitItem.isLoading"/> -->
                    <b-spinner  v-if="kitItem.isLoading" class="mr-1" small />
                    <!-- 0 % -->
                  </span>
                  <span class="status ml-1" v-show="kitItem.fileName !== ''"> 
                    <checked-icon />
                  </span>
                  
                  <span class="delete-kit" v-show="kitItem.fileName !== ''">
                    <feather-icon
                      @click="removeUploadFile(kitItem)"
                      icon="Trash2Icon"
                      class="cursor-pointer white"
                      size="16"
                    />
                  </span>
                </div>
              </div>
              <small class="text-danger" v-show="kitItem.emptyPreTempOrderRawDataGuid">
                You must upload raw data file
              </small>
            </div>
          </div>
        </div>

        <div class="warn-cost">
          <p>Having a problem uploading your File? <a href="https://illustrativedna.com/contact/" target="_blank">Contact support</a> </p>
          <hr />
          <div class="cost">Cost: <span>{{ productData != null? productData.product.currencyUnit : ''}}{{subtotal}} </span></div>
        </div>

        <div class="step-buttons-mobile">
          <button class="lab-btn back" @click="back()">Back</button>
          <button class="lab-btn next valid" @click="submit()">
            Next
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from '@/common/api.service'

import { PRODUCT_CODES, TRAIT_CHECKOUT_STEPS } from '@/common/config';
import CheckedIcon from '@/layouts/icons/CheckedIcon.vue';
import UploadIcon from '@/layouts/icons/UploadIcon.vue';
import QuantityDropdown from './others/QuantityDropdown.vue';

export default {
  components: {
    CheckedIcon,
    UploadIcon,
    QuantityDropdown,
  },
  props: {
    screen: {
      type: String,
      default: 'DESKTOP',
    },
  },
  data() {
    return {
      PRODUCT_CODES,
      TRAIT_CHECKOUT_STEPS,

      modalObject: {
        showModal: false,
        item: null,
      },
    }
  },
  methods: {
    submit() {
      /* eslint-disable */
      const kitsData = this.$store.getters['traitCheckout/getCreateOrderData'].kits;

      let isEmptyOrderRawDataGuid = false;
      kitsData.forEach(item => {
        if (item.preTempOrderRawDataGuid === null) {
          isEmptyOrderRawDataGuid = true;
          item.emptyPreTempOrderRawDataGuid = true;
        } else {
          item.emptyPreTempOrderRawDataGuid = false;
        }
      });

      if (isEmptyOrderRawDataGuid) {
        return;
      }
      
      let isEmptyPersonName = false;
      kitsData.forEach(item => {
        if (item.personName === '') {
          isEmptyPersonName = true;
          item.emptyPersonName = true;
        } else {
          item.emptyPersonName = false;
        }
      });

      if (isEmptyPersonName) {
        return;
      }

      this.next();
    },

    back() {
      this.$router.back();
    },

    openUpload(fileItem) {
      const fileInput = document.createElement('input');
      fileInput.type = 'file';

      fileInput.addEventListener('change', event => {
        /* eslint-disable*/
        const file = event.target.files[0];
        if(file.size <= 0) {
          AlertService.warning(this, 'Invalid file size...! File size must be larger than (0K)');
          return 0;
        }
        this.UploadFile(file, fileItem);
      })
      fileInput.click();
    },
    
    UploadFile(file, fileItem) {
      const formData = new FormData();
      formData.set('file', file);
      formData.set('productId', this.productData.product.id);
      formData.set('preTempOrderGuid', this.$store.state.traitCheckout.createOrderData.preTempOrderGuid);

      fileItem.isLoading = true;
      ApiService.upload('checkout/preorderfile/create', formData, this)
        .then(response => {
          fileItem.isLoading = false;
          if (response.statusCode === 200) {
            this.$store.state.traitCheckout.createOrderData.preTempOrderGuid = response.result.preTempOrderGuid;
            this.$store.commit('traitCheckout/addOrderFile', { 
              fileName: file.name, 
              id: fileItem.id, 
              preTempOrderRawDataGuid: response.result.preTempOrderRawDataGuid 
            });
          } else {
            AlertService.warning(this, response.message);
          }
        });
    },

    removeUploadFile(fileItem) {
      const queryParams = {
        preTempOrderGuid: this.$store.state.traitCheckout.createOrderData.preTempOrderGuid,
        preTempOrderRawDataGuid: fileItem.preTempOrderRawDataGuid,
      };
      fileItem.isLoading = true;
      ApiService.post('checkout/preorderfile/remove', queryParams, this)
        .then(response => {
          fileItem.isLoading = false;
          if (response.statusCode === 200) {
            this.$store.commit('traitCheckout/removeOrderFile', { id: fileItem.id });
          } else {
            AlertService.warning(this, response.message);
          }
        });
    },

    next() {
      if (this.screen === 'DESKTOP') {
        this.$router.push(`/checkout/trait/${TRAIT_CHECKOUT_STEPS.CONFIRM}/${PRODUCT_CODES.UPLOAD_TRAIT_RAW_DATA}`);
      } else if (this.screen === 'MOBILE') {
        if (this.isUserLoggedIn) {
          this.$router.push(`/checkout/trait/${TRAIT_CHECKOUT_STEPS.CONFIRM}/${PRODUCT_CODES.UPLOAD_TRAIT_RAW_DATA}`);
        } else {
          this.$router.push(`/checkout/trait/${TRAIT_CHECKOUT_STEPS.ACCOUNT}/${PRODUCT_CODES.UPLOAD_TRAIT_RAW_DATA}`);
        }
      }
    },
    prev() {
    },
  },
  computed: {
    productData() {
      return this.$store.getters['traitCheckout/getProductData'];
    },
    orderKits() {
      return this.$store.getters['traitCheckout/getCreateOrderData'].kits;
    },
    subtotal() {
      return this.$store.getters['traitCheckout/getCreateOrderData'].subtotal;
    },
  },
  mounted() {
  },
  watch: {
  },
}
</script>

<style lang="scss" scoped>

.checkout-card .upload-raw-container {
  display: flex;
  justify-content: space-between;
  padding: 1rem 1.5rem;
  height: 90%;

  .upload-raw-body {
    position: relative;

    .kits {
      max-width: 430px;
    }
  }

  .title {
    margin-top: 10px;
    h4 {
      font-size: 1.6rem;
      font-weight: bold;
      color: var(--lab-carbon-fiber);
    }
  }
  .desc {
    margin-top: 20px;
    p {
      line-height: 1rem;
      font-size: 0.7rem;
      color: var(--lab-hydrargyrum);
    }
  }

  .select-quantity {
    h6 {
      font-size: 0.7rem;
      font-weight: 600;
    }
    p {
      margin-top: 20px;
      line-height: 1rem;
      font-size: 0.7rem;
      font-weight: bold;
      color: var(--lab-carbon-fiber);
    }
  }
}

.checkout-card-mobile .upload-raw-container {
  display: block;
  padding: 0 2rem;

  .mobile-body {
    min-height: 220px;
  }
  .title {
    text-align: center;
    margin: 1rem 0;
  }
  .select-quantity {
    h6 {
      font-size: 0.8rem;
      margin: 1rem 0;
    }
    p {
      font-size: 0.8rem;
      color: var(--lab-hydrargyrum);
    }
  }
  .warn-cost {
    p {
      font-size: 0.8rem;
      line-height: 1.2rem;
      color: var(--lab-carbon-fiber);
    }
  }

  .delete-kit {
    background-color: #E88577;
    padding: 5px;
    .white {
      color: var(--lab-white);
      font-weight: bold;
    }
  }

  .kit-item {
    justify-content: space-between;
  }
  .kit-name {
    width: 150px;
  }
}

.form-control {
  border: 2px solid var(--lab-light-house) !important;
  background-color: var(--lab-white) !important;
  color: #595959 !important;
}
.form-control:disabled {
  background-color: var(--lab-light-house) !important;
}
.form-control:not(:empty) {
  background-color: var(--lab-white) !important;
}
.form-control:focus {
  background-color: var(--lab-white) !important;
}

</style>
