<template>
  <div>
    <b-alert variant="success" v-if="checkoutDataResponse.statusCode !== 200" show>
      <div class="alert-body">
        <span>{{checkoutDataResponse.message}}</span>
        <a v-if="checkoutDataResponse.statusCode === 1008" 
          @click="$router.push({name: 'login'})" class="lab-text-sunrise"> 
          Click Here For Login
        </a>
      </div>
    </b-alert>

    <div v-if="isLoadingPayment === false">
      <div class="checkout-card" v-if="screen === 'DESKTOP'">
        <div class="row confirm-g25-container">
          <div class="col-lg-6" v-if="isUserLoggedIn === false">
            <div class="confirm-card">
              <account ref="account" :checkoutModule="'traitCheckout'"/>
              <check-box-confirm ref="checkboxConfirm" v-if="isUserLoggedIn === false"/>
            </div>
          </div>
          <div class="col-lg-6" :class="{ 'col-lg-12' : isUserLoggedIn}">
            <div class="confirm-card">
              <div class="nearly-done mb-4">
                <h4>Nearly Done</h4>
              </div>
              <kits />
             
              <payment ref="payment" :checkoutModule="'traitCheckout'" :currentRouteData="currentRouteData" v-if="subtotal !== '0'"/>
              <check-box-confirm ref="checkboxConfirm" v-if="isUserLoggedIn === true"/>
            </div>
          </div>
        </div>
        <div class="d-flex align-items-center justify-content-between">
          <promotion-code checkoutModule="traitCheckout"/>
          <div class="step-buttons">
            <button class="lab-btn back" @click="prev()">Back</button>
            <button class="lab-btn next valid" @click="confirm()" :disabled="isStartLoading">
              <b-spinner v-if="$store.getters.getLoader || isStartLoading" small/>
              Confirm
            </button>
          </div>
        </div>
      </div>

      <div class="checkout-card-mobile" v-if="screen === 'MOBILE'">
        <div>
          <div class="nearly-done mb-4">
            <h4>Nearly Done</h4>
          </div>
          <payment ref="payment" :checkoutModule="'traitCheckout'"  :currentRouteData="currentRouteData" v-if="subtotal !== '0'"/>
        </div>
        <div>
          <check-box-confirm ref="checkboxConfirm" v-if="isUserLoggedIn === true"/>
        </div>
        <promotion-code checkoutModule="traitCheckout"/>
        <div class="step-buttons-mobile">
          <button class="lab-btn back" @click="prev()">Back</button>
          <button class="lab-btn next valid" @click="confirm()" :disabled="isStartLoading">
            <b-spinner v-if="$store.getters.getLoader || isStartLoading" small/>
            Confirm
          </button>
        </div>
      </div>
    </div>

    <div v-else>
      <b-alert variant="success" show>
        <div class="alert-body">
          <span>Waiting for payment response...  <b-spinner /></span>
        </div>
      </b-alert>
    </div>

  </div>
</template>

<script>
import AlertService from '@/common/alert.service'
import ApiService from '@/common/api.service';
import { TRAIT_CHECKOUT_STEPS } from '@/common/config';

import useJwt from '@/auth/jwt/useJwt'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Kits from './Kits.vue'
import Account from '../steps/Account.vue';
import Payment from '../steps/Payment.vue';
import CheckBoxConfirm from './others/CheckBoxConfirm.vue'
import PromotionCode from '../steps/PromotionCode.vue';

export default {
  components: {
    Kits,
    Account,
    Payment,
    CheckBoxConfirm,
    PromotionCode,
  },
  props: {
    currentRouteData: {
      type: Object,
    },
    screen: {
      type: String,
      default: 'DESKTOP',
    },
  },
  data() {
    return {
      TRAIT_CHECKOUT_STEPS,
      isValidUser: false,
      isValid: true,

      checkoutDataResponse: { statusCode: 200 },
      isStartLoading: false,
      isLoadingPayment: false,
    }
  },
  methods: {
    checkOrderWarning(product, orderData, queryParams) {
      if (product.productType === 1) {
        if (queryParams.preTempOrderGuid === null || queryParams.preTempOrderGuid === '') {
          AlertService.error(this, 'Please first upload raw data files')
          this.isValid = false;
          return;
        }
      }
      
      let errorMessage = null;
      orderData.kits.forEach(fileItem => {
        if (fileItem.preTempOrderRawDataGuid === null || fileItem.preTempOrderRawDataGuid === '') {
          errorMessage = 'Missing file upload! Please upload files of all kits.';
        }
      });

      if (errorMessage !== null) {
        AlertService.error(this, errorMessage);
        this.isValid = false;
      }

      if (orderData.isConfirmPrivacyPalicy === false) {
        this.isValid = false;
        this.$refs.checkboxConfirm.validateCheckbox();
      }
    },

    checkUserWarning(userData) {
      if (!userData.userEmail) {
        this.isValid = false;
      }
      if (!userData.firstName) {
        this.isValid = false;
      }
      if (!userData.lastName) {
        this.isValid = false;
      }
      if (!userData.password) {
        this.isValid = false;
      }
    },

    confirm(isPaymentSuccess = false) {
      /* eslint-disable */
      this.isStartLoading = true;
      this.isValid = true;
      const product = this.productData.product;

      const orderData = this.$store.getters['traitCheckout/getCreateOrderData'];
      let queryParams = this.$store.getters['traitCheckout/getCheckoutQuery'];

      this.checkOrderWarning(product, orderData, queryParams);

      if (this.isValid != true) {
        this.isStartLoading = false;
        return;
      }

      let userData = null;
      if (this.isUserLoggedIn === false) {
        userData = this.$store.getters['account/getUserData'];
        this.checkUserWarning(userData);
        if(this.isValid != true) {
          this.isStartLoading = false;
          return;
        }
      }

      this.checkUserEmail(userData, () => {
        if (isPaymentSuccess === false && this.subtotal !== "0") {
          this.$refs.payment.handleSubmit((paymentResult) => {
            if (paymentResult.error) {
              AlertService.warning(this, paymentResult.error.message);
              this.isStartLoading = false;
              return;
            } else {
              if (paymentResult.paymentIntent.status !== 'succeeded') {
                this.isStartLoading = false;
                return;
              }
            }

            queryParams = this.$store.getters['traitCheckout/getCheckoutQuery'];

            this.saveCheckout({ createNewOrder: queryParams, user: userData });
          });
        } else {
          queryParams = this.$store.getters['traitCheckout/getCheckoutQuery'];
          this.saveCheckout({ createNewOrder: queryParams, user: userData });
        }
      });
    },

    saveCheckout(queryParams) {
      this.$store.dispatch('traitCheckout/saveChecout', queryParams)
        .then(dataResponse => {
          this.isStartLoading = false;
          this.isLoadingPayment = false;

          if (dataResponse.statusCode === 200 || dataResponse.statusCode === 1009) {
            this.checkLogin(queryParams.user, () => {
              this.$store.commit('traitCheckout/resetOrderState');
              this.$router.push({ name: 'dashboard' })
                .then(() => {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: 'Successful',
                      icon: 'CoffeeIcon',
                      variant: 'success',
                      text: this.$t(dataResponse.message),
                    },
                  });

                  if (dataResponse.statusCode === 1009) {
                    AlertService.error(this, 'Your order has been successfully received. But there is a error with your uploaded files. please try to re-upload the files.');
                  }

                  localStorage.setItem('traitCheckout', null);
                  window.location.reload();
                });
            });

          } else {
            AlertService.error(this, dataResponse.message);
          }
        });
    },

    handlePayment() {
      if (this.$router.currentRoute.query.payment_intent) {
        this.isLoadingPayment = true;;
        ApiService.post('webhook/retrieve-payment-intent', { PaymentIntentId: this.$router.currentRoute.query.payment_intent }, this)
          .then(response => {
            if (response.statusCode === 200) {
              if (response.result.status !== 'succeeded') {
                AlertService.error(this, response.result.last_payment_error ? response.result.last_payment_error.message : 'Failed');
                this.isLoadingPayment = false;
              } else {
                this.confirm(true);
              }
            } else {
              AlertService.error(this, response.message);
              this.isLoadingPayment = false;
            }
          });
      }
    },

    checkLogin(userData, callback) {
      if (userData) {
        useJwt.login({
          userNameOrEmail: userData.userEmail,
          password: userData.password,
          rememberMe: true,
        })
          .then(loginResponse => {
            const userData = loginResponse.result
            useJwt.setToken(loginResponse.result.token)
            useJwt.setRefreshToken(loginResponse.result.token)
            useJwt.setUserData(userData)
            this.$store.commit('account/setIsLoggedIn', true);
            if (typeof callback === 'function') {
              callback();
            }
          });
      } else {
        callback();
      }
    },

    checkUserEmail(userData, callback) {
      if (userData) {
        ApiService.post(`user/checkemail`, { email: userData.userEmail }, this)
        .then(response => {
          if (response.statusCode === 200) {
            if (typeof callback === 'function') {
              callback();
            }
          } else {
            this.isStartLoading = false;
            this.checkoutDataResponse = response;
          }
        });
      } else {
        if (typeof callback === 'function') {
          callback();
        }
      }
    },

    initValidator() {
      this.$refs.account.$refs.accountValidation.validate().then(success => {
        this.isValidUser = success;
      });
    },
    validator() {
      try {
        this.initValidator();
        this.$nextTick(() => {
          this.$watch(
            () => this.$refs.account.$refs.accountValidation.validate(),
            (newValue) => {
              newValue.then(success => {
                this.isValidUser = success;
              });
            },
          );
        });
      }
      catch {
      }
    },

    prev() {
      this.$router.push(`/checkout/trait/${this.TRAIT_CHECKOUT_STEPS.UPLOAD}/${this.currentRouteData.productCode}`);
    },
  },
  computed: {
    isUserLoggedIn() {
      return this.$store.getters['account/getIsLoggedIn'];
    },
    isValidKits() {
      /* eslint-disable */
      const kits = this.$store.getters['traitCheckout/getCreateOrderData'].kits;

      return !kits.find(x => x.presetEthnicityId == null);
    },
    productData() {
      return this.$store.getters['traitCheckout/getProductData'];
    },

    subtotal() {
      const _subTotal = this.$store.getters[`traitCheckout/getOrderSubTotal`];
      return _subTotal;
    },
  },
  mounted() {
    this.validator();
    this.handlePayment();
  },
}
</script>

<style lang="scss" scoped>
.checkout-card {
  max-width: 1100px;
}
.confirm-g25-container {
  padding: 1rem;
}

.checkout-card-mobile {
  .title {
    text-align: center;
  }
  .desc {
    padding: 0.5rem 1rem;
    margin-top: 1rem;
    p {
      font-size: 0.95rem;
      color: var(--lab-hydrargyrum);
    }
  }

  .payment-container {
    margin-top: 5rem;
    height: 250px;
  }
}

.nearly-done {
  padding: 0.2rem 1.5rem;
  h4 {
    font-size: 1.5rem;
    font-weight: bold;
  }
  p {
    margin-top: 20px;
    line-height: 1rem;
    font-size: 0.7rem;
    color: var(--lab-hydrargyrum);
  }
}

.confirm-card {
  min-width: 450px
}
</style>
